import { BASE_URL } from "../Constants";
import { ApiSlice } from "./ApiSlice";

const GetDataSlice=ApiSlice.injectEndpoints({
    endpoints:(builder)=>({
        getCourses:builder.query({
            query:()=>({
                url: `${BASE_URL}/api/courses/?format=json`,
                method: 'GET'
            })
        }),
        getBlogs:builder.query({
            query:()=>({
                url: `${BASE_URL}/api/posts/?format=json`,
                method: 'GET'
            })
        }),
        getBlog:builder.mutation({
            query:(id)=>({
                url: `${BASE_URL}/api/posts/${id}?format=json`,
                method: 'GET'
            })
        }),
        getLessons:builder.query({
            query: ()=>({
                url: `${BASE_URL}/api/lessons/?format=json`,
                method:'GET'
            })
        }),
        getModules:builder.query({
            query:()=>({
                url: `${BASE_URL}/api/modules/?format=json`,
                method: 'GET'
            })
        }),
        getSubModules:builder.query({
            query:()=>({
                url: `${BASE_URL}/api/submodules/?format=json`,
                method: 'GET'
            })
        })
    })
})

export const {useGetCoursesQuery, useGetBlogsQuery, useGetBlogMutation, useGetLessonsQuery, useGetModulesQuery, useGetSubModulesQuery}= GetDataSlice;