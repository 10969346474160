import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { useGetLessonsQuery } from '../ThemeContextProvider/GetDataSlice';  

function Lessons() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const { isDarkMode } = useSelector((Store) => Store.ThemeSlice);

  const { data: lessonsData, isLoading, isError } = useGetLessonsQuery();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const filteredData = lessonsData.filter((lesson) => lesson.submodule == id);
        setData(filteredData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (lessonsData) {
      fetchData();
    }
  }, [id, lessonsData]);

  const convertToIframe = (html) => {
    return html.replace(/<oembed url="https:\/\/youtu.be\/(.*?)"><\/oembed>/g, (match, videoId) => {
      return `<div class="responsive-video-container">
                <iframe 
                  src="https://www.youtube.com/embed/${videoId}" 
                  frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowfullscreen>
                </iframe>
              </div>`;
    });
  };

  return (
    <div className="px-8 py-3">
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <ClipLoader color={isDarkMode ? '#ffffff' : '#000000'} loading={isLoading} size={44} />
        </div>
      ) : isError ? (
        <div className="text-center text-red-500">
          <p>There was an error loading the lessons. Please try again later.</p>
        </div>
      ) : data.length > 0 ? (
        data.map((lesson) => (
          <div key={lesson.id}>
            <h1 className={`${isDarkMode ? 'text-white' : 'text-black'}`}>{lesson.title}</h1>
            <div
              className="custom-prose max-w-none"
              dangerouslySetInnerHTML={{ __html: convertToIframe(lesson.description) }}
            />
          </div>
        ))
      ) : (
        <h1 className="text-white h-screen text-center font-semibold">Content has not been uploaded yet. Please check other content.</h1>
      )}
    </div>
  );
}

export default Lessons;
