import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player'; 
import { useSelector } from 'react-redux';
import { Typewriter } from 'react-simple-typewriter';

function Splice() {
  const { isDarkMode } = useSelector(Store => Store.ThemeSlice);

  return (
    <div className={`flex flex-col lg:flex-row justify-between mx-auto mb-16 bg-gradient-to-r ${isDarkMode ? 'from-sky-900 via-cyan-700 to-sky-900' : 'from-cyan-700 via-sky-800 to-cyan-700' } items-center text-white px-4 sm:px-12 md:pt-10 max-w-[100%] lg:max-w-[80%] shadow-xl relative`}>
      <div className="text-left lg:w-1/2 w-full mb-8 lg:mb-0">
      <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-2 mt-12 md:mt-24 min-h-[13px] md:min-h-[0px] md:mb-4 lg:mb-0 max-w-[90%] md:max-w-[97%]">
          <Typewriter
            words={['Learn Anytime, Anywhere!']}
            loop={false}
            cursor
            cursorStyle="|"
            typeSpeed={90}
            deleteSpeed={70}
            delaySpeed={1100}
          />
        </h1>
        <p className="text-base sm:text-lg lg:text-lg lg:mb-6 font-normal text-gray-300 mt-2">
          Kickstart, change, or elevate your career with over 4 specialized courses.
        </p>
      </div>
      <div className="lg:w-3/5 -mt-[63px] w-full flex justify-center lg:justify-end">
        <Player 
          src="https://lottie.host/ea77eb96-9627-4ee4-bfd0-5bb299f341c3/TzCE7HFC48.json" 
          className="w-full sm:w-[70%] lg:w-[79%] h-auto transition-transform transform hover:scale-105 hover:cursor-pointer duration-300" 
          loop 
          autoplay 
        />
      </div>
    </div>
  );
}

export default Splice;
