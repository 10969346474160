import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetBlogMutation } from '../ThemeContextProvider/GetDataSlice';
import ClipLoader from 'react-spinners/ClipLoader';

function Blog() {
  const [post, setPost] = useState(null);
  const { id } = useParams();

  const [getBlog, { isLoading, isError }] = useGetBlogMutation();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await getBlog(id).unwrap();
        setPost(response);
        console.log(response);
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    fetchPost();
  }, [id, getBlog]);

  const { isDarkMode } = useSelector((Store) => Store.ThemeSlice);

  return (
    <div className={`${isDarkMode ? 'bg-zinc-900 text-white' : 'bg-gray-300 brightness-105 text-black'} max-w-4xl mx-auto mt-5 p-4 lg:p-6 rounded-lg shadow-lg`}>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <ClipLoader color={isDarkMode ? "#ffffff" : "#000000"} loading={isLoading} size={44} />
        </div>
      ) : isError ? (
        <div className="text-center text-red-500">
          <p>There was an error loading the blog post. Please try again later.</p>
        </div>
      ) : (
        <>
          <h2 className="text-lg lg:text-xl font-bold mb-8 text-center">{post?.title}</h2>
          <div className="custom-prose max-w-none" dangerouslySetInnerHTML={{ __html: post?.description }} />
        </>
      )}
    </div>
  );
}

export default Blog;
