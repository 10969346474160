import { BASE_URL } from "../Constants";
import { ApiSlice } from "./ApiSlice";

const UserApi= ApiSlice.injectEndpoints({
    endpoints:(builder)=>({
        login: builder.mutation({

            query:(data)=>({
                url: `${BASE_URL}/auth/login/`,
                method: 'POST',
                body: data
            })
        }),

        signup:builder.mutation({
            query:(data)=>({
                url: `${BASE_URL}/auth/register/`,
                method: 'POST',
                body: data
            })
        }),

        logout:builder.mutation({
            query:()=>({
                url: `${BASE_URL}/auth/logout/`,
                method: 'POST'
            })
        }),

        refresh:builder.mutation({
            query:()=>({
                url: `${BASE_URL}/auth/token/refresh/`,
                method: 'POST'
            })
        }),

        user: builder.mutation({
            query: () => ({
                url: `${BASE_URL}/auth/profile/`,
                method: 'GET',
            }),
        })

    })
})

export const {useLoginMutation, useLogoutMutation, useSignupMutation, useUserMutation, useRefreshMutation}= UserApi;