import React from 'react'; 
import { useSelector } from 'react-redux';

const Newsletter = () => {
  const { isDarkMode } = useSelector(Store => Store.ThemeSlice);

  return (
    <div className={`bg-gradient-to-r mt-6 lg:mt-16 ${isDarkMode ? 'bg-zinc-900 text-white' : 'bg-gray-300  brightness-105 text-black'} px-4 sm:px-6 shadow-xl`}>
      <div className="max-w-[90%] lg:max-w-[80%] mx-auto flex flex-col md:flex-row items-center justify-between">
        <div className="md:w-1/2 w-full text-left mb-5 md:mb-0">
          <h1 className="text-xl sm:text-3xl lg:text-4xl font-bold mt-5 lg:mt-0 mb-4">
            Subscribe to Our Newsletter
          </h1>
          <p className={`text-sm sm:text-lg ${isDarkMode ? 'text-gray-300' :  'text-gray-700' } mb-6`}>
            Get Updates from us via the most informational Newsletter.
          </p>
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            <input
              type="text"
              placeholder="Email here..."
              className="w-full px-4 py-2 text-black rounded focus:outline-none focus:ring-2 focus:ring-cyan-500"
            />
            <button className="bg-sky-700 hover:bg-sky-600 text-white px-6 py-2 rounded-md">
              Subscribe
            </button>
          </div>
        </div>
        <div className="md:w-[31%] w-full hidden md:flex justify-center md:justify-end -mt-12 md:mt-0  ">
          <img
            alt="Newsletter Image"
            src="https://static.vecteezy.com/system/resources/previews/012/066/503/original/postal-envelope-with-notice-3d-render-png.png"
            className="rounded-lg w-48 sm:w-56 md:w-full h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
