import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useGetModulesQuery, useGetSubModulesQuery } from '../ThemeContextProvider/GetDataSlice';
import ClipLoader from 'react-spinners/ClipLoader';

function Modules() {
  const { id } = useParams();
  const [moduleData, setModuleData] = useState([]);
  const [subModuleData, setSubModuleData] = useState([]);
  const [activeModule, setActiveModule] = useState(null);

  const { data: modules, isLoading: isModulesLoading, isError: isModulesError } = useGetModulesQuery();
  const { data: subModules, isLoading: isSubModulesLoading, isError: isSubModulesError } = useGetSubModulesQuery();

  useEffect(() => {
    if (modules && subModules) {
      const filteredModules = modules.filter(module => module.course == id);
       setModuleData(filteredModules);
      setSubModuleData(subModules);
    }
    scrollTo(0, 0);
  }, [id, modules, subModules]);

  const { isDarkMode } = useSelector((Store) => Store.ThemeSlice);

  const toggleModule = (clickedModule) => {
    setActiveModule(activeModule === clickedModule ? null : clickedModule);
  };

  if (isModulesLoading || isSubModulesLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ClipLoader color={isDarkMode ? '#ffffff' : '#000000'} loading={isModulesLoading || isSubModulesLoading} size={44} />
      </div>
    );
  }

  if (isModulesError || isSubModulesError) {
    return (
      <div className="text-center text-red-500">
        <p>There was an error loading the modules. Please try again later.</p>
      </div>
    );
  }

  return (
    <div className={`min-h-screen font-semibold flex flex-col items-center mt-3`}>
      {moduleData.map((module) => (
        <div key={module.id} className={` ${isDarkMode ? "bg-zinc-900 text-white" : "bg-gray-300 brightness-105 text-black"} w-full max-w-[22rem] md:max-w-md p-3 py-0 my-2 border-2 rounded-md text-start`}>
          <div className='flex justify-between items-center' onClick={() => toggleModule(module.id)} >
            <h1 className="text-base md:text-lg cursor-pointer flex items-center mt-4">
              <span className="ml-2">{module.title}</span>
            </h1>
            {activeModule === module.id ? '▲' : '▼'}
          </div>

          {activeModule === module.id && (
            <div>
              {subModuleData
                .filter(sub => sub.module == module.id)
                .map((sub) => (
                  <Link to={`/Lessons/${sub.id}`} key={sub.id}>
                    <div className={`${isDarkMode ? "text-sky-500" : "text-sky-600"} ml-2 text-start py-2`}>{sub.title}</div>
                  </Link>
                ))
              }
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Modules;
