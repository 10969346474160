import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Base query to handle the initial API request
const baseQuery = fetchBaseQuery({
  baseUrl: 'https://vknan.pythonanywhere.com',
  credentials: 'include',  // Include credentials (cookies) in requests
});

// A base query that includes logic to refresh tokens if needed
const baseQueryWithReauth = async (args, api, extraOptions) => {
  // Try the original request
  let result = await baseQuery(args, api, extraOptions);

  // If we get a 401 Unauthorized, try to refresh the token
  if (result.error && result.error.status === 401) {
    // Try refreshing the token
    const refreshResult = await baseQuery('/auth/token/refresh/', api, extraOptions);

    if (refreshResult.data) {
      // Retry the original query with the new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      // If refresh fails, dispatch a logout action or handle it as needed
      api.dispatch({ type: 'auth/logout' }); // Adjust this to match your actual logout action
    }
  }

  return result;
};

// Create an API slice with base query and token refresh logic
export const ApiSlice = createApi({
  baseQuery: baseQueryWithReauth,  // Use the enhanced base query
  tagTypes: ['courses', 'user'],   // Define any tag types
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: '/auth/login/',
        method: 'POST',
        body: data,
      }),
    }),
    signup: builder.mutation({
      query: (data) => ({
        url: '/auth/register/',
        method: 'POST',
        body: data,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/auth/logout/',
        method: 'POST',
      }),
    }),
    refresh: builder.mutation({
      query: () => ({
        url: '/auth/token/refresh/',
        method: 'POST',
      }),
    }),
    profile: builder.query({
      query: () => ({
        url: '/auth/profile/',
        method: 'GET',
      }),
    }),
  }),
});

export const { useLoginMutation, useSignupMutation, useLogoutMutation, useRefreshMutation, useProfileQuery } = ApiSlice;
