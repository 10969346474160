import { configureStore } from "@reduxjs/toolkit";
import ThemeSlice from "./ThemeSlice";
import { ApiSlice } from "./ApiSlice";

const Store = configureStore({
   reducer: { 
       [ApiSlice.reducerPath]: ApiSlice.reducer
      ,ThemeSlice: ThemeSlice
   },
   middleware:(getDefaultMiddleware)=>
         getDefaultMiddleware().concat(ApiSlice.middleware),
   devTools:true
})

export default Store;

