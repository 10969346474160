import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const UserProfile = () => {
    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Function to get the access token from cookies
    const getAccessToken = () => {
        return Cookies.get('access_token');
    };

    // Function to refresh the access token
    const refreshAccessToken = async () => {
        try {
            const refreshToken = Cookies.get('refresh_token');
            const response = await axios.post('/auth/token/refresh/', {
                refresh: refreshToken,
            });
            const newAccessToken = response.data.access;
            // Update the access token in cookies
            Cookies.set('access_token', newAccessToken, { secure: false, sameSite: 'None' });
            return newAccessToken;
        } catch (err) {
            setError('Unable to refresh access token. Please log in again.');
            throw err;
        }
    };

    const fetchUserProfile = async (token) => {
        try {
            const response = await axios.get('/auth/profile/', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setUserDetails(response.data);
        } catch (err) {
            if (err.response && err.response.status === 401) {
                // Access token might be expired; try refreshing it
                try {
                    const newAccessToken = await refreshAccessToken();
                    // Retry fetching user profile with new access token
                    await fetchUserProfile(newAccessToken);
                } catch (refreshError) {
                    setError('Session expired. Please log in again.');
                }
            } else {
                setError('Failed to fetch user details.');
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const token = getAccessToken();
        if (token) {
            fetchUserProfile(token);
        } else {
            setError('No access token found. Please log in.');
            setLoading(false);
        }
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div className="text-red-500">{error}</div>;

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">User Profile</h1>
            {userDetails ? (
                <div>
                    <p><strong>Username:</strong> {userDetails.username}</p>
                    <p><strong>Email:</strong> {userDetails.email}</p>
                    {/* Add more user details as needed */}
                </div>
            ) : (
                <p>No user details available.</p>
            )}
        </div>
    );
};

export default UserProfile;
