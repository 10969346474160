import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css"; 
import { RouterProvider } from "react-router-dom";
import { appRoutes } from "./App";
import { Provider } from "react-redux";
import Store from "./ThemeContextProvider/Store";

const Index = () => {
  return (
    <Provider store={Store}>
      <RouterProvider router={appRoutes} />
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("app"));

root.render(<Index />);
