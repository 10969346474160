import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Navbar from "./Components/NavBar";
import Home from "./Components/Home";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Login from "./Authentication/Login";
import Register from "./Authentication/Register";
import UserProfile from "./Authentication/UserProfile";
import About from "./Components/About";
import { Provider, useSelector } from "react-redux";
import Store from "./ThemeContextProvider/Store";
import Blogs from "./Components/Blogs";
import Blog from "./Components/Blog"; 
import Modules from "./Components/Modules";
import Lessons from "./Components/Lessons";
import Search from "./Components/Search";
import NotFound from "./Components/NotFound";

function AppLayout() {
  const { isDarkMode } = useSelector(Store => Store.ThemeSlice);

  return (
    <div className={`${isDarkMode ? 'bg-black' : 'bg-gray-50 brightness-105'}`}>
      <Provider store={Store}>
        <Navbar />
        <Outlet />
      </Provider>
    </div>
  );
}

export const appRoutes = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/Login",
        element: <Login />,
      },
      {
        path: "/Register",
        element: <Register />,
      },
      {
        path: "/Profile",
        element: <UserProfile />,
      },
      {
        path: "/About",
        element: <About />,
      },
      {
        path: "/Blogs",
        element: <Blogs />,
      },
      {
        path: "/Blogs/:id",
        element: <Blog />,
      },
      {
        path: "/Modules/:id",
        element: <Modules />,
      },{
        path: "/Lessons/:id",
        element: <Lessons />,
      },
      {
        path: "/Search/:keyword",
        element: <Search />,
      },
      {
        path: '*',
        element: <NotFound />
      }
    ],
  },
]);
