import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBars, faTimes, faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import { Link , useNavigate} from 'react-router-dom';  
import { useDispatch, useSelector } from 'react-redux'; 
import { toggleTheme } from '../ThemeContextProvider/ThemeSlice';

const Navbar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);  
  const navigate=useNavigate();

  const dispatch = useDispatch();
  const { isDarkMode } = useSelector(Store => Store.ThemeSlice);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    console.log('Search Query:', searchQuery);
    if(searchQuery.length>0){
      navigate(`/Search/${searchQuery}`);
      }
      else{
        navigate('/')
      }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

   useEffect(() => {
    const handleClickOutside = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className={`${isDarkMode ? 'bg-zinc-900 shadow-xl': 'bg-gray-100 border-b-[1px] border-gray-300 '} p-4 z-50`}>
      <style>
        {`@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');`}
      </style>
      <div className="container mx-auto flex flex-wrap items-center justify-between">
        <Link to={'/'}>
          <div className={`${isDarkMode ? 'text-sky-500': 'text-sky-600 brightness-90'} text-lg md:text-xl font-bold`} style={{ fontFamily: 'Tenor Sans, sans-serif' }}>
            Navikonline
          </div>
        </Link>

        <button className={`block lg:hidden ${isDarkMode ? "text-white" : "text-black"} focus:outline-none`} onClick={toggleMenu}>
          {menuOpen ? (
            <FontAwesomeIcon icon={faTimes} className="w-6 h-5 py-[8px]" />
          ) : (
            <FontAwesomeIcon icon={faBars} className="w-6 h-5 py-[8px]" />
          )}
        </button>

        <form onSubmit={handleSearchSubmit} className="hidden lg:flex flex-grow max-w-md mx-auto">
          <input
            type="text"
            placeholder="Search for courses"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className={`${isDarkMode ? 'border-0' : 'text-black border-y-2 border-l-2 border-gray-300'} w-full px-4 py-2 rounded-l-md focus:outline-none`}
          />
          <button type="submit" className="bg-sky-700 text-white px-4 py-2 rounded-r-md hover:cursor-pointer hover:bg-sky-600">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </form>

        <div ref={menuRef} className={`${menuOpen ? 'block' : 'hidden'} lg:flex flex-col lg:flex-row items-center space-y-0 lg:space-x-4 mb-1 lg:mb-0`}>
          <Link to={'Blogs'}>
            <button className={`${isDarkMode ? 'text-white hover:text-gray-300 hover:underline' : 'text-black hover:text-gray-800 hover:underline'} mr-4 lg:mr-0`}>Blogs</button>
          </Link>
          <Link to={'Login'}>
            <button className={`${isDarkMode ? 'text-white hover:text-gray-300 hover:underline' : 'text-black hover:text-gray-800 hover:underline'}`}>Login</button>
          </Link>
          <Link to={'Register'}>
            <button className={`${isDarkMode ? 'bg-white text-black hover:bg-gray-200' : 'bg-sky-700 text-white hover:bg-sky-600'} hidden lg:block px-4 lg:py-2 rounded-md`}>
              Join for Free
            </button>
          </Link>

          <button onClick={() => dispatch(toggleTheme())} className={`ml-3 md:ml-0 p-1 px-2 lg:p-2 lg:px-3 min-w-10 rounded-lg ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-gray-200 text-black'}`}>
            <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} />
          </button>
        </div>
      </div>

      <form onSubmit={handleSearchSubmit} className="lg:hidden flex mt-4">
        <input
          type="text"
          placeholder="Search for courses"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className={`${isDarkMode ? 'border-0' : 'text-black border-y-2 border-l-2 border-gray-300'} w-full px-4 py-2 rounded-l-md focus:outline-none`}
        />
        <button type="submit" className="bg-sky-700 text-white px-4 py-2 rounded-r-md hover:cursor-pointer hover:bg-sky-600">
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </form>
    </nav>
  );
};

export default Navbar;
