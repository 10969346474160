import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetBlogsQuery } from '../ThemeContextProvider/GetDataSlice';
import ClipLoader from 'react-spinners/ClipLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

function Blogs() {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate(); 

  const { data, isLoading, isError } = useGetBlogsQuery();
  
  const { isDarkMode } = useSelector((Store) => Store.ThemeSlice);

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleOpen = (id) => {
    navigate(`/Blogs/${id}`);
  };

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
    console.log("toggleMenu triggered");
  };
  

  const filteredPosts = selectedCategory === 'All' ? data : data?.filter((post) => post.category.name === selectedCategory);

  const categories = data ? ['All', ...new Set(data.map((post) => post.category.name))] : [];

  return (
    <div className="s-container flex flex-col md:flex-row justify-center pb-12 mt-5">
      {isLoading ? (
        <div className="flex justify-center items-center h-screen w-full">
          <ClipLoader color={isDarkMode ? "#ffffff" : "#000000"} loading={isLoading} size={44} />
        </div>
      ) : isError ? (
        <div className="flex justify-center items-center h-screen w-full">
          <p className="text-red-500">An error occurred while loading the blogs. Please try again later.</p>
        </div>
      ) : (
        <>
          <div className={`filter-sidebar p-1 px-4 md:p-4 ml-6 mx-5 md:mx-0 md:w-[20%] mb-4 md:mb-0 md:h-screen ${isDarkMode ? 'bg-zinc-900 text-white' : 'bg-gray-300 brightness-105 text-black'} rounded-md`}>
            
            <div className='flex justify-between mx-2 md:mx-0'>
              <h2 className="text-[16.29px] md:text-lg font-bold mt-2 md:mb-3">Filter by Category</h2>
              <button onClick={toggleMenu} className='md:hidden'>
                {isMenuOpen 
                  ? <FontAwesomeIcon icon={faTimes} className="w-6 h-5 py-[8px]" />
                  : <FontAwesomeIcon icon={faBars} className="w-6 h-5 py-[8px]" />
                }
              </button>
            </div>
            
            <ul className={`md:block ${isMenuOpen ? 'block' : 'hidden'}`} >
              {categories.map((category) => (
                <li key={category} className="mb-2">
                  <button
                    className={`category-button w-full text-left p-[5px] md:p-2 rounded text-[15px] md:text-base ${
                      selectedCategory === category
                        ? 'bg-sky-700 text-white'
                        : `${isDarkMode ? 'bg-gray-800 text-gray-300' : 'bg-slate-200 text-black'}`
                    } hover:bg-sky-700 hover:text-white`}
                    onClick={() => handleCategoryChange(category)}
                  >
                    {category}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="ml-2 blog-posts px-4 md:w-[78%] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
            {filteredPosts.map((post) => (
              <div
                key={post.id}
                className={`blog-post h-fit ${isDarkMode ? 'text-white bg-zinc-900' : 'text-black bg-gray-300 brightness-105'} shadow-md rounded-md p-4 md:p-6 transition-transform transform hover:-translate-y-2 hover:cursor-pointer`}
                onClick={() => handleOpen(post.id)}
              >
                <img src={post.thumbnail} alt={post.title} className="w-full h-48 object-cover rounded-t-md" />
                <h3 className="text-md font-bold mt-4">{post.title.replace(/<[^>]+>/g, '').substring(0, 110)}...</h3>
                <p className={`mt-2 text-sm ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>{post.description.replace(/<[^>]+>/g, '').substring(0, 193)}...</p>
                <button className="read-more-button mt-2 text-sky-500 hover:text-sky-400 font-semibold">Read More</button>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Blogs;
